import { Component, DoCheck, Input, ViewChild } from '@angular/core';
import { GenericChartData } from '../chart-types';
import { ColorsPizzaGraphArray } from '../colors-graph';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements DoCheck {

  @Input() data: GenericChartData[] = [];
  @Input() view: any[] = [undefined, 230];
  @Input() label: string = '';
  @Input() labelClass: string = '' ;
  @Input() labelFontSize: string = '';
  @Input() labelTextStyle: string = '';
  @Input() gradient: boolean = false;
  @Input() showLegend: boolean = true;
  @Input() showLabels: boolean = false;
  @Input() isDoughnut: boolean = false;
  @Input() legendTitle: string = '';
  @Input() legendPosition: string = 'right';
  @Input() scheme: {} = {
    domain: [...ColorsPizzaGraphArray]
  };

  @ViewChild('chart') chart;

  colorScheme = {
    domain: [...ColorsPizzaGraphArray]
  };

  constructor() {
    Object.assign(this.data);
  }

  ngDoCheck() {
    this.onResize();
  }

  onResize(event?: any) {
    const height = window.screen.width < 720 ? 200 : 320;
    this.view = [this.width*0.76, height];

  }

  get width(): number {
    const width = window.screen.width < 720 ? window.screen.width : window.screen.width * 0.4 - 65;
    return this.chart?.nativeElement?.offsetWidth || width;
  }

  get dataIsEmpty(): boolean {
    return !this.data || this.data?.length === 0;
  }

  tooltipFormat(tooltip) {
    const dataTooltip = tooltip.data;
    const sum = this.data.map(x => x.value).reduce((acumulador, valorAtual) => acumulador + valorAtual);
    return dataTooltip && `${parseFloat((dataTooltip.value * 100 / sum).toString()).toFixed(2)}%`;
  }

  labelFormat(label) {
    const copyOfData = JSON.parse(JSON.stringify(this.data));
    const labelData = copyOfData.find(x => x.data.name === label).value;
    const sum = copyOfData.map(x => x.data.value).reduce((acumulador, valorAtual) => acumulador + valorAtual);
    return labelData && `${parseFloat((labelData * 100 / sum).toString()).toFixed(2)}%`;
  }

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
