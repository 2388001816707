import { Component, OnInit } from '@angular/core';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public usuarioSession: any;

  constructor(private readonly storageService: StorageService) { }

  ngOnInit(): void {
    this.usuarioSession = this.storageService.getFromLocalStorage('usuario',true);
  }

  get anoAtual() {
    const dataAtual = new Date();
    const anoatual = dataAtual.getFullYear();
    return anoatual;
  }

  get ambienteQA() {
    return window.location.href.includes('codatahml');
  }

}
