export const URL_CONFIG = Object.freeze({
  url_autocomplete: 'autocomplete',
  url_perfis: 'perfil',
  url_usuario: 'usuario',
  url_restricoes: 'restricao',
  url_perfil_docente: 'perfilDocente',
  url_cep: 'cep',
  url_profissional: 'profissional',
  url_profissional_dados_pessoais: 'profissional/dados-pessoais',
  url_profissional_endereco: 'profissional/endereco',
  url_profissional_vacinas: 'vacinas',
  url_profissional_formacao_academica: 'profissional/formacao-academica',
  url_minhas_turmas: 'minhas-turmas',
  url_minhas_matriculas: 'profissional/minhas-matriculas',
  url_diario_classe_registro_avaliacao: 'diario-classe-registro-avaliacao',
  url_diario_classe_get_estudantes_for_create: 'get-estudantes-for-create',
  url_diario_classe_verificar_somativa: 'verificar-somativa',
  url_diario_classe_registro_aula: 'diario-classe-registro-aula',
  url_diario_classe_aluno: 'diario-classe-aluno',
  url_formacao_turma_aluno: 'formacao-turma-aluno',
  url_professor: 'professor',
  url_alunos: 'alunos',
  url_turmas: 'turmas',
  url_boletim: 'boletim',
  url_aluno_frequencia: 'frequencia',
  url_detalhar_estudante: 'detalhar-estudante',
  url_evento: 'evento',
  url_unidade_tematica: 'unidadeTematica',
  url_plano_ensino: 'planoDeEnsino',
  url_registro_avaliacao_novo: 'registroavaliacao',
  url_boletim_grafico_frequencia: 'detalhar-estudante-grafico-frequencia'
});
