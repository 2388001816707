import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
})
export class CheckboxComponent {
  @Input() id: string;
  @Input() isChecked: boolean;
  @Input() disabled: boolean;

  @Output() onChange = new EventEmitter();
}
