import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Util } from '../../utils/util';
import { Calendar } from 'primeng/calendar'; // Altere para a importação correta do PrimeNG 9

@Component({
  selector: 'app-date-picker-range',
  templateUrl: './date-picker-range.component.html',
  styleUrls: ['./date-picker-range.component.scss'],
})
export class DatePickerRangeComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() name: string = '';
  @Input() customClass: any = '';
  @Input() id;

  @Input() view: string = 'date';
  @Input() dateFormat: string = 'dd/mm/yy';
  @Input() placeholder: string = 'dd/mm/aaaa';

  @Input() maxDate: Date = null;
  @Input() minDate: Date = null;

  @Input() yearRange = '2000:2030';
  @Input() showButtonBar = 'true';
  rangeDates: Date[];

  @Output() onChange = new EventEmitter<any>();
  @Output() onFocusOut = new EventEmitter<any>();
  @Output() dateSelected = new EventEmitter<Date[]>();

  @ViewChild('datePick') datePickElement: Calendar;

  changeSubscription: Subscription;
  inputModel: Date[] = [];

  locale = Util.getBrasilLocales();

  constructor() { }

  ngOnInit(): void {
    this.changeSubscription = this.formControl.valueChanges.subscribe(
      (value) => {
        if (Array.isArray(value)) {
          this.inputModel = value.map(date => Util.formatStringToDate(date));
        } else {
          this.inputModel = [Util.formatStringToDate(value)];
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.changeSubscription.unsubscribe();
   }

  get formControl(): FormControl {
    return this.form.get(this.name) as FormControl;
  }

  get invalid(): boolean {
    return this.form.get(this.name).invalid;
  }

  get disabled() {
    return this.formControl.disabled;
  }

  change() {
    const filteredDates = this.rangeDates.filter(date => date !== null);
    if (filteredDates.length === 2) {
      const formattedValue = this.rangeDates.map(date => this.getValueFormatted(date));
      this.formControl.setValue(formattedValue);
    } else {
      this.formControl.setValue(this.getValueFormatted(filteredDates));
    }

    this.formControl.markAsTouched();
    this.onChange.emit(this.formControl.value);
    this.onFocusOut.emit();
    this.dateSelected.emit(this.rangeDates);
  }


  blur() {
    this.formControl.markAsTouched();
    this.onFocusOut.emit(this.formControl.value);
  }

  getValueFormatted(value: any) {
    if (Array.isArray(value)) {
      return value.map(date => (date ? moment(date).format('YYYY-MM-DD HH:mm:SS') : ''));
    } else {
      return value ? moment(value).format('YYYY-MM-DD HH:mm:SS') : '';
    }
  }

}
