<div class="card">
  <div class="card-body">
    <div class="row" *ngIf="!dataIsEmpty">
      <div class="col-12">
        <ng-content select="[button-top]"></ng-content>
        <h5 [ngClass]="[labelClass, labelFontSize, labelTextStyle]"><strong> {{ label }} </strong></h5>
      </div>
      <div #ref class="col-12 col-md-7 d-flex flex-column justify-content-center">
        <ng-content content class="d-flex"></ng-content>
      </div>
      <div class="d-flex align-items-center" [ngClass]="ref.children.length == 0 ? 'col-12' : 'col-12 col-md-5'">
        <div id="chart" #chart>
          <ngx-charts-pie-chart
            [view]="view"
            [results]="data"
            [gradient]="gradient"
            [scheme]="scheme"
            [legend]="showLegend"
            [animations]="false"
            (window:load)="onResize($event)"
            (window:resize)="onResize($event)"
            [legendPosition]="legendPosition"
            [legendTitle]="legendTitle"
            [labels]="showLabels"
            [labelFormatting]="labelFormat"
            [tooltipText]="tooltipFormat"
            [doughnut]="isDoughnut"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
          </ngx-charts-pie-chart>
        </div>
      </div>
    </div>

    <div class="row container-fluid mt-4" style="height: 240px;" *ngIf="dataIsEmpty">
      <p>
        Não há dados a serem exibidos.
      </p>
    </div>
  </div>
</div>
