import { Component, Input, ViewChild } from '@angular/core';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { AutocompleteService } from '@core/services/autocomplete.service';
import { MultiSelect } from 'primeng';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
})
export class MultiselectComponent extends AutocompleteComponent {

  @ViewChild(MultiSelect, { static: false }) multiselectElement: MultiSelect;

  @Input() selectedItemsLabel = 'items selecionados';
  @Input() maxSelectedLabels = 3;
  @Input() showToggleAll = false;
  @Input() virtualScroll = true;

  constructor(public autocompleteService: AutocompleteService) {
    super(autocompleteService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getOptions();
  }
}
