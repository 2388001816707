import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { StorageService } from '@core/services/storage.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const NAMES_PROJECT  = environment.namespaceProject;

@Injectable({
  providedIn: 'root'
})
export class ControlAccessGuard implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly storageService: StorageService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const usuario = this.storageService.getFromLocalStorage('usuario',true);
    if (this.isIsFirsAccess(usuario)) {
      return false;
    }

    if(this.naoTemMatriculaEAno()){
      return false
    }

    return true;
  }

  isIsFirsAccess(usuario) {
    if (usuario !== null && usuario.primeiroAcesso) {
      this.router.navigate(['/auth/primeiro-acesso']);
      return true;
    }
    return false;
  }

  naoTemMatriculaEAno(){
    const anoLetivo = this.storageService.getFromLocalStorage('anoLetivo');
    const matriculaNomeEscola = this.storageService.getFromLocalStorage('matriculaNomeEscola');

    if (anoLetivo === null || matriculaNomeEscola == null) {
      this.router.navigate(['/auth/selecionar-ano-matricula']);
      return true;
    }
    return false;
  }

}
